body {
	margin: 0;
	font-family: 'Nunito', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 1.2em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
	height: 100vh;
	display: flex;
	flex-direction: column;
	background: linear-gradient(to top left, #0a4686, #0a8683);
}
